import React, { useState, useEffect } from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  Button,
  IconButton,
  Paper,
  Drawer,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const Dashboard: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    certificateNo: '',
    expiryDate: '',
    issueDate: '',
    type: '',
    remarks: '',
    file: null as File | null,
    otherType: '', // Added for "Others" option
  });
  const [documents, setDocuments] = useState<any[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      alert('You must be logged in to view this page.');
      window.location.href = '/login'; // Redirect to login page
      return;
    }

    const fetchDocuments = async () => {
      try {
        const response = await axios.get('https://backend.comcareservices.com/api/documents', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDocuments(response.data);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [token]);

  const toggleDrawer = (open: boolean) => setIsDrawerOpen(open);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setFormData((prev) => ({ ...prev, file }));
  };

  const handleSave = async () => {
    if (!token) return alert('Please log in.');

    const formPayload = new FormData();
    formPayload.append('name', formData.name);
    formPayload.append('certificateNo', formData.certificateNo);
    formPayload.append('expiryDate', formData.expiryDate);
    formPayload.append('type', formData.type);
    formPayload.append('issueDate', formData.issueDate);
    formPayload.append('remarks', formData.remarks);
    formPayload.append('otherType', formData.otherType); // Added for custom type
    if (formData.file) formPayload.append('file', formData.file);

    try {
      const endpoint = selectedDocument
        ? 'https://backend.comcareservices.com/api/update_document'
        : 'https://backend.comcareservices.com/api/add_document';

      const response = await axios.post(endpoint, formPayload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert(selectedDocument ? 'Document updated successfully!' : 'Document added successfully!');
        setFormData({
          name: '',
          certificateNo: '',
          expiryDate: '',
          issueDate: '',
          type: '',
          file: null,
          remarks: '',
          otherType: '', // Reset otherType after saving
        });
        setSelectedDocument(null);
        toggleDrawer(false);

        // Refresh the documents list
        const newResponse = await axios.get('https://backend.comcareservices.com/api/documents', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setDocuments(newResponse.data);
      }
    } catch (error) {
      console.error('Error saving document:', error);
      alert('Failed to add/update document. Please try again.');
    }
  };

  const handleEdit = (document: any) => {
    setSelectedDocument(document);
    setFormData({
          name: document.name,
          certificateNo: document.certificateNo,
          expiryDate: document.expiryDate,
          issueDate: document.issueDate,
          type: document.type,
          file: null, // Do not pre-fill file input
            remarks: document.remarks,
          otherType: document.type === 'Others' ? document.otherType : '', // Set 'otherType' if it was used
        });
    toggleDrawer(true);
  };

  const handleAddNew = () => {
    setSelectedDocument(null);
    setFormData({
          name: '',
          certificateNo: '',
          expiryDate: '',
          issueDate: '',
          type: '',
          file: null,
          remarks: '',
          otherType: '', // Reset 'otherType'
        });
    toggleDrawer(true);
  };

  const handleView = async (filePath: string) => {
    try {
      const token = localStorage.getItem('token'); // Fetch your token from storage or context
  
      // Fetch the file from the backend with the required headers
      const response = await fetch(`https://backend.comcareservices.com/uploads/${filePath}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error fetching the file: ${response.statusText}`);
      }
  
      // Convert the response to a Blob
      const blob = await response.blob();
  
      // Create a temporary URL for the Blob and open it
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
  
      // Optional: Revoke the URL after a delay to free up resources
      setTimeout(() => URL.revokeObjectURL(url), 1000);
    } catch (error) {
      console.error('Error fetching the file:', error);
    }
  };
  

  const handleSignOut = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <Box sx={{ bgcolor: '#1c1c1c', height: '100vh', marginTop: '15vh', color: 'white' }}>
      {/* AppBar */}
      <AppBar position="fixed" sx={{ bgcolor: '#e2dfe2', zIndex: 1201 }}>
        <Toolbar>
          <img src="./mintiplogo.png" alt="Mint Repo" style={{ height: 40, marginRight: 10 }} />
          <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold', fontSize: 24 }}>
            
          </Typography>
          <IconButton color="#000">
            <SettingsIcon />
            <Button color="#000" onClick={handleSignOut}>Sign Out</Button>
          </IconButton>
          <IconButton color="#000">
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Search and Add Button */}
      <Box sx={{ position: 'fixed', top: 64, width: '100%', zIndex: 1200, bgcolor: '#1c1c1c', p: 3 }}>
        {/* <Paper component="form" sx={{ display: 'flex', alignItems: 'center', bgcolor: '#444', p: '2px 4px', mb: 2 }}>
          <IconButton sx={{ p: '10px' }} aria-label="search">
            <SearchIcon sx={{ color: 'white' }} />
          </IconButton>
          <InputBase sx={{ ml: 1, flex: 1, color: 'white' }} placeholder="Search" inputProps={{ 'aria-label': 'search' }} />
        </Paper> */}
        <Button variant="contained" fullWidth sx={{ bgcolor: '#666', color: 'white', textTransform: 'none', mb: 2 }} onClick={handleAddNew}>
          Add Document
        </Button>
      </Box>

      {/* Display Documents */}
      <Box sx={{ pt: '140px', px: 3, height: 'calc(100vh - 140px)', overflowY: 'auto' }}>
        {documents.map((document, index) => (
          <Paper key={index} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, bgcolor: '#333', cursor: 'pointer' }} onClick={() => handleEdit(document)}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'white' }}>
                {document.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'white' }}>Certificate No: {document.certificateNo}</Typography>
              <Typography variant="body2" sx={{ color: 'white' }}>Expiry Date: {document.expiryDate}</Typography>
            </Box>
            <Typography variant="body2" sx={{ color: 'white', bgcolor: '#555', p: 1, borderRadius: 1 }}>
              {document.type}
            </Typography>
          </Paper>
        ))}
      </Box>

      {/* Drawer for Adding or Editing Document */}
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
        <Box sx={{ p: 3, bgcolor: '#1c1c1c', color: 'white' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {selectedDocument ? 'Edit Document' : 'Add Document'}
          </Typography>
          

<FormControl fullWidth  sx={inputStyles}>
          <InputLabel>Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="type"
            value={formData.type}
            onChange={handleInputChange}
            label="Type"
            sx={{
                color: 'white',  // Make the text of the selected item white
                '& .MuiSelect-icon': {
                  color: 'white',  // Make the dropdown icon white
                },
                '& .MuiInputLabel-root': {
                  color: 'white',  // Make the label white
                },
                '& .MuiOutlinedInput-root': {
                  borderColor: 'white',  // Optional: To ensure the border is white as well
                },
                '& .MuiSelect-root:focus': {
                  backgroundColor: '#333', // Optional: Set background color on focus
                }
              }}
           
          >
            <MenuItem value="Trademark">Trademark</MenuItem>
            <MenuItem value="Patent">Patent</MenuItem>
            <MenuItem value="Copyright">Copyright</MenuItem>
          </Select></FormControl>

          <TextField
            fullWidth
            label="Company Name"
            variant="outlined"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            sx={inputStyles}
          />
          <TextField
            fullWidth
            label="Certificate No"
            variant="outlined"
            name="certificateNo"
            value={formData.certificateNo}
            onChange={handleInputChange}
            sx={inputStyles}
          />
          <TextField
            fullWidth
            label="Issue Date"
            type="date"
            variant="outlined"
            name="issueDate"
            value={formData.issueDate}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            sx={inputStyles}
          />
          <TextField
            fullWidth
            label="Expiry Date"
            type="date"
            variant="outlined"
            name="expiryDate"
            value={formData.expiryDate}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            sx={inputStyles}
          />

          <TextField
            fullWidth
            label="Remarks"
            variant="outlined"
            name="remarks"
            value={formData.remarks}
            onChange={handleInputChange}
            sx={inputStyles}
          />

    <FormGroup>
      <FormControlLabel required control={<Checkbox />} label=" I hereby confirm that I am the rightful owner of this intellectual property and agree to abide by the terms and conditions set forth by the company. " />
    </FormGroup>  

{!selectedDocument && (
        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ bgcolor: '#666', color: 'white', textTransform: 'none', mb: 2 }}
        >
          Upload PDF
          <input
            type="file"
            accept="application/pdf"
            hidden
            onChange={handleFileChange}
          />
        </Button>
      )}
      {selectedDocument && selectedDocument.file && (
        <Button
          variant="contained"
          fullWidth
          sx={{ bgcolor: '#666', color: 'white', textTransform: 'none', mb: 2 }}
          onClick={() => handleView(selectedDocument.file)} // Open the file in a new tab
        >
          View Uploaded PDF
        </Button>
      )}
      <Button
        variant="contained"
        fullWidth
        sx={{ bgcolor: '#666', color: 'white', textTransform: 'none' }}
        onClick={handleSave}
      >
        {selectedDocument ? 'Update' : 'Save'}
      </Button>
        </Box>
      </Drawer>
    </Box>
  );
};

const inputStyles = {
    mb: 2,
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: '#42a5f5',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#42a5f5',
      },
      '& input': {
        color: 'white',
      },
    },
  };

export default Dashboard;
