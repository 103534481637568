import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Card, CardContent } from '@mui/material';
import axios from 'axios';

const Registration: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [gstNumber, setGstNumber] = useState<string>('');
  const [panCard, setPanCard] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [pincode, setPincode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleRegister = async () => {
    // Basic validation
    if (!username || !email || !password || !confirmPassword || !companyName || !phoneNumber || !address ) {
      setError('All fields are required!');
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }

    // Reset error message
    setError('');
    setLoading(true);

    // Send registration data to Flask backend
    try {
      const response = await axios.post('https://backend.comcareservices.com/api/register', {
        username,
        email,
        companyName,
        phoneNumber,
        gstNumber,
        panCard,
        website,
        address,
        country,
        pincode,
        password,
      });

      // Handle successful registration
      if (response.status === 201) {
        alert('Registration successful!');
        // Redirect to login page or other action
        window.location.href = '/login';
      }
    } catch (error) {
      // Handle error from backend
      setError('Registration failed! Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#0d0e1c', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card sx={{
        maxWidth: 400, width: '100%', padding:2, 
        background: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
        backdropFilter: 'blur(10px)', // Glass effect
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Card shadow
        borderRadius: 3,
      }}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom align="center" color="white">
            Register
          </Typography>

          {error && (
            <Typography color="error" variant="body2" gutterBottom align="center">
              {error}
            </Typography>
          )}

          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={inputStyles}
          />

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={inputStyles}
          />

          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            sx={inputStyles}
          />

          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="normal"
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            sx={inputStyles}
          />

          <TextField
            label="GST Number (Optional)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
            sx={inputStyles}
          />

          <TextField
            label="PAN Card"
            variant="outlined"
            fullWidth
            margin="normal"
            value={panCard}
            onChange={(e) => setPanCard(e.target.value)}
            sx={inputStyles}
          />

          <TextField
            label="Website"
            variant="outlined"
            fullWidth
            margin="normal"
            type="url"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            sx={inputStyles}
          />

          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            sx={inputStyles}
          />

          

          
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={inputStyles}
          />

          <TextField
            label="Confirm Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={inputStyles}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleRegister}
            sx={{
              marginTop: 2,
              backgroundColor: '#42a5f5',
              '&:hover': {
                backgroundColor: '#1e88e5',
              },
            }}
            disabled={loading}
          >
            {loading ? 'Registering...' : 'Register'}
          </Button>

          <Typography variant="h6" component="h6" gutterBottom align="center" color="white">
            Already have an account? <a href="/login">Login</a>
          </Typography>

        </CardContent>
      </Card>
    </Box>
  );
};

// Styling for textfields
const inputStyles = {
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: '#42a5f5',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#42a5f5',
    },
    '& input': {
      color: 'white',
    },
  },
};

export default Registration;
