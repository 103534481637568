// src/components/Login.tsx
import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Card, CardContent } from '@mui/material';
import axios from 'axios';

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = async () => {
    // Basic validation
    if (!email || !password) {
      setError('Both fields are required!');
      return;
    }

    setError('');
    setLoading(true);

    try {
      // Sending login data to Flask backend
      const response = await axios.post('https://backend.comcareservices.com/api/login', {
        email,
        password,
      });

      // Handle successful login (assuming JWT token is returned)
      if (response.status === 200) {
        // Save the JWT token to localStorage (or use it in your app as needed)
        localStorage.setItem('token', response.data.token);
        console.log('Token saved:', response.data.token);
        alert('Login successful');
        // Redirect to a protected page (e.g., dashboard)
        window.location.href = '/dashboard';  // Replace with your dashboard page
      }
    } catch (error) {
      // Handle login failure
      setError('Invalid email or password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ height: '100vh', backgroundColor: '#0d0e1c', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card sx={{
        maxWidth: 400, width: '100%', padding: 2,
        background: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
        backdropFilter: 'blur(10px)', // Glass effect
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Card shadow
        borderRadius: 3,
      }}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom align="center" color="white">
            Login
          </Typography>

          {error && (
            <Typography color="error" variant="body2" gutterBottom align="center">
              {error}
            </Typography>
          )}

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={email}
            autoComplete="off" // Disable autofill
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiInputLabel-root': {
                color: 'white', // White label text
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white', // White border color
                },
                '&:hover fieldset': {
                  borderColor: '#42a5f5', // Blue hover border
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#42a5f5', // Blue focused border
                },
                '& input': {
                  color: 'white', // White text color inside input
                },
              },
            }}
          />

          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            autoComplete="off" // Disable autofill
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              '& .MuiInputLabel-root': {
                color: 'white', // White label text
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white', // White border color
                },
                '&:hover fieldset': {
                  borderColor: '#42a5f5', // Blue hover border
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#42a5f5', // Blue focused border
                },
                '& input': {
                  color: 'white', // White text color inside input
                },
              },
            }}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
            sx={{
              marginTop: 2,
              backgroundColor: '#42a5f5',
              '&:hover': {
                backgroundColor: '#1e88e5', // Darker blue on hover
              },
            }}
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </Button>

          <Typography variant="h6" component="h6" gutterBottom align="center" color="white">
            Don't have an account? <a href="/register">Register</a>
          </Typography>

        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
